<template>
  <v-card class="mt-5">
    <v-toolbar
      dense
      flat
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multiproject.capacityReceived.title')" />
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-row v-if="assignedList.length" align="center">
        <v-col cols="grow">
          <v-card
            v-for="({
              resource,
              assigned,
              color,
              name,
              lastCapacity,
              lastNumberOfRequests,
            }) in assignedList"
            :key="`resource-${resource}`"
            :color="color"
            class="my-2"
            dark
          >
            <v-card-text class="headline text-center white--text">
              <div>
                <span v-text="$t('multiproject.capacityReceived.message.firstPart', { name })" />
                <v-icon large v-text="`mdi-numeric-${assigned}-circle-outline`" />
                <span v-text="$t('multiproject.capacityReceived.message.lastPart')" />
              </div>
              <div>
                <div
                  v-if="lastCapacity"
                  class="d-inline-block rounded-pill mx-3 px-2
                    secondary lighten-2 white--text subtitle-2"
                >
                  {{ $t('multiproject.capacityReceived.lastCapacity') }}
                  :
                  {{ lastCapacity }}
                </div>
                <div
                  v-if="lastNumberOfRequests"
                  class="d-inline-block rounded-pill mx-3 px-2
                    secondary lighten-2 white--text subtitle-2"
                >
                  {{ $t('multiproject.capacityReceived.lastNumberOfRequests') }}
                  :
                  {{ lastNumberOfRequests }}
                </div>
                <v-tooltip
                  top
                  max-width="500"
                  :open-on-click="true"
                  :open-on-hover="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-inline-block" v-bind="attrs" v-on="on">
                      <v-icon>
                        mdi-information-outline
                      </v-icon>
                    </div>
                  </template>
                  <div class="row align-center">
                    <div
                      class="col-2 text-right"
                      v-text="$t('multiproject.capacityReceived.lastCapacity')"
                    />
                    <div
                      class="col"
                      v-text="$t('multiproject.capacityReceived.lastCapacityDetail')"
                    />
                  </div>
                  <div class="row align-center">
                    <div
                      class="col-2 text-right"
                      v-text="$t('multiproject.capacityReceived.lastNumberOfRequests')"
                    />
                    <div
                      class="col"
                      v-text="$t('multiproject.capacityReceived.lastNumberOfRequestsDetail')"
                    />
                  </div>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto">
          <v-btn
            :loading="loadingUpdateGantt"
            :disabled="loadingUpdateGantt"
            color="accent"
            @click="updateGantt"
            v-text="$t('multiproject.capacityReceived.button')"
          />
        </v-col>
      </v-row>

      <v-alert
        v-else
        text
        prominent
        color="primary"
        icon="mdi-information"
      >
        <v-row no-gutters align="center" class="pl-5">
          <v-col cols="grow">
            <h3 class="headline" v-text="$t('multiproject.capacityReceived.noCapacity')" />
          </v-col>

          <v-col cols="auto" class="mx-1">
            <v-btn
              :loading="loadingUpdateGantt"
              :disabled="loadingUpdateGantt"
              color="accent"
              @click="updateGantt"
              v-text="$t('multiproject.capacityReceived.button')"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'

import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'CapacityReceivedContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingUpdateGantt: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    resourceNameList () {
      return _mapValues(_keyBy(this.game.resourceList, 'color'), 'name')
    },
    resourceLastCapacityList () {
      return _mapValues(_keyBy(this.game.resourceList, 'color'), 'lastCapacity')
    },
    resourceLastNumberOfRequestList () {
      return _mapValues(_keyBy(this.game.resourceList, 'color'), 'lastNumberOfRequests')
    },
    assignedList () {
      return this.currentPlayer.gantt
        .find(tasks => tasks.some(task => task.done < task.size))
        .filter(task => task.done < task.size)
        .map(task => ({
          resource: task.resource,
          color: this.$options.colors[task.resource],
          name: this.resourceNameList[task.resource],
          lastCapacity: this.resourceLastCapacityList[task.resource],
          lastNumberOfRequests: this.resourceLastNumberOfRequestList[task.resource],
          assigned: task.assignment - task.done,
        }))
        .filter(task => task.assigned > 0)
    },
  },
  methods: {
    async updateGantt () {
      this.loadingUpdateGantt = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.updateGantt)
      this.loadingUpdateGantt = false
    },
  },
  colors: {
    red: 'red',
    green: 'green',
    yellow: 'amber',
    blue: 'indigo',
  },
}
</script>
