var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-toolbar',{attrs:{"dense":"","flat":"","dark":"","color":"secondary"}},[_c('v-spacer'),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.title'))}}),_c('v-spacer')],1),_c('v-card-text',[(_vm.assignedList.length)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"grow"}},_vm._l((_vm.assignedList),function(ref){
          var resource = ref.resource;
          var assigned = ref.assigned;
          var color = ref.color;
          var name = ref.name;
          var lastCapacity = ref.lastCapacity;
          var lastNumberOfRequests = ref.lastNumberOfRequests;
return _c('v-card',{key:("resource-" + resource),staticClass:"my-2",attrs:{"color":color,"dark":""}},[_c('v-card-text',{staticClass:"headline text-center white--text"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.message.firstPart', { name: name }))}}),_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(("mdi-numeric-" + assigned + "-circle-outline"))}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.message.lastPart'))}})],1),_c('div',[(lastCapacity)?_c('div',{staticClass:"d-inline-block rounded-pill mx-3 px-2\n                  secondary lighten-2 white--text subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t('multiproject.capacityReceived.lastCapacity'))+" : "+_vm._s(lastCapacity)+" ")]):_vm._e(),(lastNumberOfRequests)?_c('div',{staticClass:"d-inline-block rounded-pill mx-3 px-2\n                  secondary lighten-2 white--text subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t('multiproject.capacityReceived.lastNumberOfRequests'))+" : "+_vm._s(lastNumberOfRequests)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","max-width":"500","open-on-click":true,"open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"col-2 text-right",domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.lastCapacity'))}}),_c('div',{staticClass:"col",domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.lastCapacityDetail'))}})]),_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"col-2 text-right",domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.lastNumberOfRequests'))}}),_c('div',{staticClass:"col",domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.lastNumberOfRequestsDetail'))}})])])],1)])],1)}),1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.loadingUpdateGantt,"disabled":_vm.loadingUpdateGantt,"color":"accent"},domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.button'))},on:{"click":_vm.updateGantt}})],1)],1):_c('v-alert',{attrs:{"text":"","prominent":"","color":"primary","icon":"mdi-information"}},[_c('v-row',{staticClass:"pl-5",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"grow"}},[_c('h3',{staticClass:"headline",domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.noCapacity'))}})]),_c('v-col',{staticClass:"mx-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.loadingUpdateGantt,"disabled":_vm.loadingUpdateGantt,"color":"accent"},domProps:{"textContent":_vm._s(_vm.$t('multiproject.capacityReceived.button'))},on:{"click":_vm.updateGantt}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }